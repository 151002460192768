import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex w-full flex-col items-center justify-between gap-6 rounded-3xl bg-blue-zodiac-950 p-8 shadow-md md:flex-row md:gap-0" };
const _hoisted_2 = { class: "flex flex-col gap-1" };
const _hoisted_3 = { class: "bg-gradient-to-r from-blue-500 to-sky-300 bg-clip-text text-sm font-semibold text-transparent" };
const _hoisted_4 = { class: "text-2xl font-semibold" };
const _hoisted_5 = { class: "relative flex w-full flex-col gap-3 md:flex-row md:justify-end md:gap-1" };
const _hoisted_6 = ["placeholder"];
const _hoisted_7 = { key: 0, class: "absolute -bottom-6 text-xs text-red-500" };
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from '@tanstack/vue-query';
import { subscribersApi } from '@/api/subscribers';
export default /*@__PURE__*/ _defineComponent({ __name: 'subscribe-form', setup(__props) { const { t } = useI18n(); const newEmail = ref(''); const subscribedEmail = ref(''); const { isError, isSuccess, mutate } = useMutation(() => { if (!newEmail.value)
        return Promise.reject(); subscribedEmail.value = newEmail.value; const newSubscriber = { email: newEmail.value, applyUpdates: false, blogUpdates: true }; return subscribersApi.create(newSubscriber); }); const showSubscribed = computed(() => isSuccess.value && subscribedEmail.value === newEmail.value); return (_ctx, _cache) => { const _component_kalio_button = _resolveComponent("kalio-button"); return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('landing.subscribeForm.subtitle')), 1), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('landing.subscribeForm.title')), 1)]), _createElementVNode("div", _hoisted_5, [_withDirectives(_createElementVNode("input", { id: "subscribe-input", "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => newEmail.value = $event), type: "email", placeholder: _unref(t)('landing.subscribeForm.placeholder'), class: "h-11 rounded-md border border-slate-700 bg-slate-900 px-4 text-sm md:h-8" }, null, 8, _hoisted_6), [[_vModelText, newEmail.value]]), _createVNode(_component_kalio_button, { disabled: showSubscribed.value, label: showSubscribed.value ? _unref(t)('landing.subscribeForm.subscribed') : _unref(t)('landing.subscribeForm.subscribe'), variant: "primary", class: "h-11 w-full md:h-8 md:w-28", onClick: _cache[1] || (_cache[1] = $event => _unref(mutate)()) }, null, 8, ["disabled", "label"]), _unref(isError) ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(t)('landing.subscribeForm.error')), 1)) : _createCommentVNode("", true)])]); }; } });
