import { createApp } from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { camelizeKeys } from 'humps';
import InlineSvg from 'vue-inline-svg';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import '@/css/application.scss';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { Form as VeeForm } from 'vee-validate';
import LandingCompanyList from '@/components/landing/landing-company-list.vue';
import KalioAccordionRow from '@/components/shared/kalio-accordion-row.vue';
import ImpersonatedUserCard from '@/components/internal/impersonated-user-card.vue';
import DemoDayCompanyList from '@/components/landing/demo-day-company-list.vue';
import LandingSubscribeForm from '@/components/landing/subscribe-form.vue';
import KalioButton from '@/components/shared/kalio-button.vue';
import KalioApplicationProcessDates from '@/components/apply/base-application-process-stages.vue';
import LandingFaq from '@/components/landing/faq.vue';
import LandingMobileNavbarHamburger from '@/components/landing/mobile-navbar-hamburger.vue';
import CutOutCornerCard from '@/components/shared/cut-out-corner-card.vue';
import ProgressCheckpoint from '@/components/shared/progress-checkpoint.vue';
import KalioSocialMediaIcons from '@/components/shared/kalio-social-media-icons.vue';
import useScreen from '@/hooks/screen';
import '@/config/vee-validate';
import i18n from '@/plugins/i18n';

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    setup() {
      const { screen } = useScreen();

      return {
        screen,
      };
    },
  });

  app.config.globalProperties.$filters = {
    camelizeKeys,
  };

  app.component('kalio-button', KalioButton);
  app.component('kalio-application-process-stages', KalioApplicationProcessDates);
  app.component('landing-subscribe-form', LandingSubscribeForm);
  app.component('landing-faq', LandingFaq);
  app.component('landing-mobile-navbar-hamburger', LandingMobileNavbarHamburger);
  app.component('cut-out-corner-card', CutOutCornerCard);
  app.component('progress-checkpoint', ProgressCheckpoint);
  app.component('kalio-social-media-icons', KalioSocialMediaIcons);
  app.component('kalio-accordion-row', KalioAccordionRow);
  app.component('impersonated-user-card', ImpersonatedUserCard);
  app.component('demo-day-company-list', DemoDayCompanyList);
  app.component('landing-company-list', LandingCompanyList);

  app.component('InlineSvg', InlineSvg);
  app.component('vee-form', VeeForm);

  app.use(FloatingVue);
  app.use(i18n);
  app.use(VueQueryPlugin, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    },
  });
  app.mount('#vue-app');

  if (process.env.RAILS_ENV === 'production') {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      integrations: [new Integrations.Vue({ Vue: app, attachProps: true })],
    });
  }

  return app;
});
