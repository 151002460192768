import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex w-full gap-2 rounded-2xl bg-blue-zodiac-950 p-6 md:gap-4" };
const _hoisted_2 = { class: "size-16 overflow-hidden rounded-xl bg-slate-300 md:size-20" };
const _hoisted_3 = ["src"];
const _hoisted_4 = { class: "w-full flex-1" };
const _hoisted_5 = { class: "flex flex-col gap-3 md:gap-2" };
const _hoisted_6 = { class: "text-xl font-semibold text-slate-50" };
const _hoisted_7 = { class: "text-sm text-slate-50" };
const _hoisted_8 = { class: "flex flex-row gap-4 text-xs text-slate-300" };
const _hoisted_9 = { key: 0, class: "flex items-center gap-1" };
const _hoisted_10 = { class: "flex gap-1" };
const _hoisted_11 = ["href"];
export default /*@__PURE__*/ _defineComponent({ __name: 'landing-company-card', props: { company: {} }, setup(__props) { const props = __props; return (_ctx, _cache) => { const _component_inline_svg = _resolveComponent("inline-svg"); return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", { class: "size-full object-cover", src: props.company.isotypeUrl }, null, 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString(props.company.name), 1), _createElementVNode("p", _hoisted_7, _toDisplayString(props.company.slogan), 1), _createElementVNode("div", _hoisted_8, [props.company.batch ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_inline_svg, { src: require('assets/images/icons/brand/platanus-isotype.svg'), class: "size-3" }, null, 8, ["src"]), _createElementVNode("span", null, _toDisplayString(props.company.batch.batchSeason), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_10, [_createVNode(_component_inline_svg, { src: require('assets/images/landing/world-gradient.svg'), class: "size-4" }, null, 8, ["src"]), _createElementVNode("span", null, _toDisplayString(props.company.country), 1)])])])]), _createElementVNode("a", { href: props.company.profilePath, target: "_blank", class: "relative top-0 size-6 text-slate-300 hover:opacity-75" }, [_createVNode(_component_inline_svg, { src: require('assets/images/icons/share.svg'), class: "size-full" }, null, 8, ["src"])], 8, _hoisted_11)]); }; } });
